import {makeStyles, createStyles, Theme} from '@material-ui/core';
import {MAGENTA, PRIMARY, TEXT_SECONDARY, WHITE} from '../../../const/colors';
import {desktop, LARGE_DESKTOP_BREAKPOINT} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
        },
        containerPadding: {
            padding: desktop.contentSmallerSpacing,
        },
        header: {
            fontSize: 18,
            lineHeight: '32px',
            fontWeight: 'bold',
            [theme.breakpoints.up('sm')]: {
                fontSize: 24,
            },
        },
        subheader: {
            marginTop: 7,
            color: PRIMARY,
            fontSize: 16,
            lineHeight: '24px',
        },
        ratingWrapper: {
            marginTop: 8,
        },
        textClipWrapper: {
            marginTop: 8,
        },
        extraDetailsTitle: {
            marginTop: 16,
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#666666',
        },
        avatar: {
            margin: '0 3px 14px 12px',
            float: 'right',
            width: 90,
            height: 90,
            [theme.breakpoints.up('sm')]: {
                width: 120,
                height: 120,
            },
            [`@media ${LARGE_DESKTOP_BREAKPOINT}`]: {
                width: 166,
                height: 166,
            },
        },
        description: {
            margin: 0,
            fontSize: 14,
            lineHeight: '24px',
            color: TEXT_SECONDARY,
            marginTop: 8,
            marginBottom: 8,
        },
        truncationToggleText: {
            color: MAGENTA,
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        },
    }),
);

export default useStyles;
