import {
    Box,
    Button,
    Card,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    getDateDifferenceFromNowInDays,
    getRelativeDay,
    getDateTime,
} from '../../../utils/date';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectAppointmentPurchaseOptions,
    selectSelectedAppointment,
} from '../../../store/timeSlotsSlice';
import {selectAuthUserData} from '../../../store/auth';
import AppointmentPatientPurchaseOptions from '../AppointmentPatientPurchaseOptions/AppointmentPatientPurchaseOptions';
import AppointmentAnonymousPurchaseOptions from '../AppointmentAnonymousPurchaseOptions/AppointmentAnonymousPurchaseOptions';
import {desktop, mobile} from '../../../const/sizes';
import {AppointmentPurchaseType} from '../../../const/appointmentPurchaseType';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useAppointment from '../../../hooks/appointment/useAppointment';
import useQuery from '../../../hooks/useQuery';
import {DateTime} from 'luxon';
import {getLanguage} from '../../../utils/translation';
import i18n from '../../../i18n/config';
import {SelectButtons} from '../../index';
import {getFilteredAppointmentTypes} from '../../../utils/appointment';
import React, {useEffect, useState} from 'react';
import {SelectButtonOption} from '../../common/SelectButtons/SelectButtons';
import RegistrationCard from '../../individualPackage/RegistrationCard/RegistrationCard';
import LoginCard from '../../individualPackage/LoginCard/LoginCard';
import {useHistory} from 'react-router';
import PhoneConfirmationCard from '../../individualPackage/PhoneConfirmationCard/PhoneConfirmationCard';
import {PHONE_NUMBER_CONFIRMATION} from '../../../const/auth';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: mobile.contentLargerSpacing,
    },
    term: {
        fontSize: 20,
        fontWeight: 700,
    },
    selectButton: {
        [theme.breakpoints.up('lg')]: {
            width: 180,
            marginRight: 10,
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            padding: desktop.contentSmallerSpacing,
        },
    },
}));

type AppointmentSettingsProps = {
    onSubmit?: (
        appointmentType: string,
        appointmentPurchaseType: AppointmentPurchaseType,
        addPatientToQueue: boolean,
        individualPackageDraftId?: number,
        patientGroupDraftId?: number,
    ) => void;
    selectedChildId: number;
    disabled: boolean;
};

type VisibleCards = {
    login: boolean;
    register: boolean;
    phoneConfirmation: boolean;
};

const visibleCardsInitialState = {
    login: false,
    register: true,
    phoneConfirmation: false,
};

const AppointmentSettings = ({
    onSubmit,
    selectedChildId,
    disabled,
}: AppointmentSettingsProps) => {
    const query = useQuery();
    const history = useHistory();
    const selectedAppointment = useAppSelector(selectSelectedAppointment);
    const purchaseOptions = useAppSelector(selectAppointmentPurchaseOptions);
    const authUserData = useAppSelector(selectAuthUserData);
    const appointmentId = parseInt(query.get('appointmentId'));
    const {appointment: replacedAppointment} = useAppointment(appointmentId);
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {briefMedic} = selectedAppointment;
    const [visibleCards, setVisibleCards] = useState<VisibleCards>(
        visibleCardsInitialState,
    );

    const channelFromQuery = query.get('channel');
    const medicalServiceId = parseInt(query.get('medicalServiceId'));
    const currentMedicalServiceData = briefMedic.medicalServices.find(
        m => m.id === medicalServiceId,
    );
    const availableChannels = {
        videoChannel:
            briefMedic?.videoChannel && currentMedicalServiceData?.videoChannel,
        chatChannel:
            briefMedic?.chatChannel && currentMedicalServiceData?.chatChannel,
        callChannel:
            briefMedic?.callChannel && currentMedicalServiceData?.callChannel,
    };

    const filteredAppointmentTypes = getFilteredAppointmentTypes(
        t,
        availableChannels.videoChannel,
        availableChannels.chatChannel,
        availableChannels.callChannel,
    );

    const initialSelectedType =
        filteredAppointmentTypes.find(
            c => c.value?.toLowerCase() === channelFromQuery?.toLowerCase(),
        ) || filteredAppointmentTypes[0];

    const [selectedType, setSelectedType] =
        useState<SelectButtonOption>(initialSelectedType);

    const language = getLanguage(i18n?.language);

    const classes = useStyles();

    const appointmentDate = (date: DateTime) => {
        if (language === 'EN')
            return getDateDifferenceFromNowInDays(date) < 2
                ? `${getRelativeDay(date)} ${getDateTime(date, 'hh:mm a')}`
                : getDateTime(date, 'hh:mm a');
        else
            return getDateDifferenceFromNowInDays(date) < 2
                ? `${getRelativeDay(date)} ${getDateTime(date, 'HH:mm')}`
                : getDateTime(date, 'HH:mm');
    };

    const handleSubmitAppointmentReplacement = () => {
        onSubmit(
            replacedAppointment.type,
            'AppointmentReplacement',
            false,
            null,
            null,
        );
    };

    useEffect(() => {
        if (!authUserData)
            setVisibleCards({
                login: false,
                register: true,
                phoneConfirmation: false,
            });
        else {
            if (authUserData.role === PHONE_NUMBER_CONFIRMATION) {
                setVisibleCards({
                    login: false,
                    register: false,
                    phoneConfirmation: true,
                });
            } else {
                setVisibleCards({
                    login: false,
                    register: false,
                    phoneConfirmation: false,
                });
            }
        }
    }, [authUserData]);

    const renderReplacementTile = () => (
        <>
            <Box mt={1}>
                {t('currentAppointmentDate')}:{' '}
                {replacedAppointment &&
                    appointmentDate(replacedAppointment?.startDate)}
            </Box>
            <Button
                variant={'contained'}
                color={'secondary'}
                onClick={handleSubmitAppointmentReplacement}
                style={{
                    marginTop: desktop.contentSmallerSpacing,
                    fontWeight: 'bold',
                }}
            >
                {t('changeForNewDate')}
            </Button>
        </>
    );

    const handleSuccessLoginOrRegister = () => {
        query.set('channel', selectedType?.value as string);
        history.replace({search: query.toString()});
    };

    const handleSubmitPurchase = (
        appointmentType: string,
        appointmentPurchaseType: AppointmentPurchaseType,
        addPatientToQueue: boolean,
        individualPackageDraftId?: number,
        patientGroupDraftId?: number,
    ) => {
        onSubmit(
            selectedType.value as string,
            appointmentPurchaseType,
            addPatientToQueue,
            individualPackageDraftId,
            patientGroupDraftId,
        );
    };

    return (
        <>
            <Card
                elevation={0}
                style={{
                    marginBottom: 16,
                }}
            >
                <Grid
                    container
                    direction="column"
                    className={classes.container}
                >
                    <Typography className={classes.term}>
                        {purchaseOptions.appointmentReplacementOption
                            ? t('newAppointmentDate')
                            : t('appointmentDate')}
                        :{' '}
                        {selectedAppointment &&
                            appointmentDate(selectedAppointment?.startDate)}
                    </Typography>
                    <Box mt={1} mb={1}>
                        {briefMedic && (
                            <SelectButtons
                                options={getFilteredAppointmentTypes(
                                    t,
                                    availableChannels.videoChannel,
                                    availableChannels.chatChannel,
                                    availableChannels.callChannel,
                                )}
                                onChange={setSelectedType}
                                buttonStyles={classes.selectButton}
                                containerStyles={classes.buttonsContainer}
                                value={selectedType}
                            />
                        )}
                    </Box>
                    {authUserData ? (
                        purchaseOptions.appointmentReplacementOption ? (
                            renderReplacementTile()
                        ) : (
                            <AppointmentPatientPurchaseOptions
                                onSubmit={handleSubmitPurchase}
                                selectedChildId={selectedChildId}
                                disabled={disabled}
                            />
                        )
                    ) : (
                        <AppointmentAnonymousPurchaseOptions />
                    )}
                </Grid>
            </Card>

            {visibleCards.register && (
                <RegistrationCard
                    onLoginClick={() =>
                        setVisibleCards({
                            login: true,
                            register: false,
                            phoneConfirmation: false,
                        })
                    }
                    submitLabel={t('createAccountAndBookAppointment')}
                    onSubmit={handleSuccessLoginOrRegister}
                />
            )}
            {visibleCards.phoneConfirmation && (
                <PhoneConfirmationCard
                    submitLabel={t('createAccountAndBookAppointment')}
                    onSubmit={handleSuccessLoginOrRegister}
                />
            )}
            {visibleCards.login && (
                <LoginCard
                    onRegisterClick={() =>
                        setVisibleCards({
                            login: false,
                            register: true,
                            phoneConfirmation: false,
                        })
                    }
                    onSubmit={handleSuccessLoginOrRegister}
                />
            )}
        </>
    );
};

export default AppointmentSettings;
