import React, {useEffect, useState} from 'react';
import {TwoColumnLayout} from '../../components';
import individualPackagesProvider from '../../services/individualPackagesProvider';
import {useParams} from 'react-router';
import {IndividualPackageDto} from '../../types/individualPackages';
import UniformPackage from '../../components/individualPackage/UniformPackage/UniformPackage';
import IndividualPackageDetails from '../../components/individualPackage/IndividualPackageDetails/IndividualPackageDetails';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import TopBarRegular from '../../components/Layout/TopBar/TopBar';
import ResellerPackage from '../../components/individualPackage/ResellerPackage/ResellerPackage';
import OwnPackage from '../../components/individualPackage/OwnPackage/OwnPackage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.down('sm')]: {
            leftColumnWrapper: {
                padding: 20,
                backgroundColor: 'white',
                marginBottom: 16,
            },
        },
    }),
);
const IndividualPackagePage = () => {
    const [individualPackage, setIndividualPackage] =
        useState<IndividualPackageDto | null>(null);
    const {id} = useParams<{id: string}>();
    const classes = useStyles();

    const fetchIndividualPackage = async () => {
        try {
            const data =
                await individualPackagesProvider.getIndividualPackageInstance(
                    parseInt(id),
                );
            setIndividualPackage(data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchIndividualPackage();
    }, []);

    return (
        <TwoColumnLayout>
            <TwoColumnLayout.Header>
                <TopBarRegular />
            </TwoColumnLayout.Header>
            <TwoColumnLayout.Main>
                <TwoColumnLayout.LeftColumn>
                    <Box className={classes.leftColumnWrapper}>
                        <IndividualPackageDetails
                            name={individualPackage?.name}
                            description={individualPackage?.description}
                            imageUri={individualPackage?.imageUri}
                            text={individualPackage?.text}
                            brochureUri={individualPackage?.brochureUri}
                            isQbCard={individualPackage?.isQbCardRequired}
                            isUnilink={
                                individualPackage?.isResellerCodeRequired
                            }
                        />
                    </Box>
                </TwoColumnLayout.LeftColumn>
                <TwoColumnLayout.RightColumn>
                    {individualPackage?.isQbCardRequired && (
                        <UniformPackage
                            individualPackageDraftId={
                                individualPackage?.draftId
                            }
                            qbServiceId={individualPackage?.externalId}
                            price={individualPackage?.price}
                        />
                    )}
                    {individualPackage?.isResellerCodeRequired && (
                        <ResellerPackage
                            individualPackageDraftId={
                                individualPackage?.draftId
                            }
                            price={individualPackage?.price}
                        />
                    )}
                    {!individualPackage?.isQbCardRequired &&
                        !individualPackage?.isResellerCodeRequired && (
                            <OwnPackage
                                individualPackageDraftId={
                                    individualPackage?.draftId
                                }
                                price={individualPackage?.price}
                            />
                        )}
                </TwoColumnLayout.RightColumn>
            </TwoColumnLayout.Main>
        </TwoColumnLayout>
    );
};

export default IndividualPackagePage;
