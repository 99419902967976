import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import calendarSectionUseStyles from '../../appointmentsCalendar/appointmentsCalendarSectionStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useEffect, useMemo, useState} from 'react';
import {RegionalBranch} from '../../../types/insuranceDeclaration';
import useStyles from './PatientInsuranceNFZDeclarationStyles';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import {insuranceDeclarationApi} from '../../../api/insuranceDeclaration/insuranceDeclarationApi';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '../../common/Accordion/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ErrorOutline} from '@material-ui/icons';

type Declaration = {
    hasPublicInsurance: boolean;
    nfzRegionalBranchCode: string;
};

enum InsuranceOption {
    INSURED = 'insured',
    NOT_INSURED = 'notInsured',
}
interface Props {
    onSubmit: (data: Declaration) => void;
    defaultExpanded?: boolean;
}
const PatientInsuranceNFZDeclaration = ({onSubmit, defaultExpanded}: Props) => {
    const calendarSectionStyles = calendarSectionUseStyles();
    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.ACCOUNT);
    const {t: prescriptionT} = useTranslation(i18nNamespaces.PRESCRIPTIONS);

    const [insuranceOption, setInsuranceOption] =
        useState<InsuranceOption | null>(null);
    const [regionalBranchList, setRegionalBranchList] = useState<
        RegionalBranch[]
    >([]);

    const [selectedRegionalBranchCode, setSelectedRegionalBranchCode] =
        useState<RegionalBranch['code'] | null>(null);

    const [showAlert, setShowAlert] = useState<boolean>(true);

    const fetchRegionalBranches = async () => {
        try {
            const {data} = await insuranceDeclarationApi.getNfzRegionalBranch();
            setRegionalBranchList(data.result);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchRegionalBranches();
    }, []);

    const isSendingDisabled = useMemo(
        () =>
            (!selectedRegionalBranchCode &&
                insuranceOption === InsuranceOption.INSURED) ||
            !insuranceOption,
        [selectedRegionalBranchCode, insuranceOption],
    );

    return (
        <Box className={classes.section}>
            <Accordion
                square
                defaultExpanded={defaultExpanded || false}
                onChange={(e, expanded) => {
                    setShowAlert(!expanded);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('insuranceNFZDeclaration')}
                        </h4>
                        {showAlert && (
                            <ErrorOutline className={classes.alertIcon} />
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.container}>
                            <Box>{t('insuranceNFZDeclarationReason')}</Box>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-label="insurance"
                                name="insurance"
                                value={insuranceOption}
                                onChange={e =>
                                    setInsuranceOption(
                                        e.target.value as InsuranceOption,
                                    )
                                }
                            >
                                <FormControlLabel
                                    value={InsuranceOption.INSURED}
                                    control={<Radio />}
                                    label={prescriptionT('insured')}
                                />
                                {insuranceOption === InsuranceOption.INSURED &&
                                    regionalBranchList.length && (
                                        <>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.select}
                                            >
                                                <InputLabel id="selectRegionalBranchLabel">
                                                    {prescriptionT(
                                                        'selectRegionalBranch',
                                                    )}
                                                </InputLabel>
                                                <CustomSelect
                                                    labelId={
                                                        'selectRegionalBranchLabel'
                                                    }
                                                    value={
                                                        selectedRegionalBranchCode ??
                                                        ''
                                                    }
                                                    label={t(
                                                        'selectRegionalBranch',
                                                    )}
                                                    onChange={e =>
                                                        setSelectedRegionalBranchCode(
                                                            e.target
                                                                .value as RegionalBranch['code'],
                                                        )
                                                    } //
                                                    fullWidth
                                                >
                                                    {regionalBranchList.map(
                                                        regionalBranchItem => (
                                                            <MenuItem
                                                                value={
                                                                    regionalBranchItem.code
                                                                }
                                                                key={
                                                                    regionalBranchItem.code
                                                                }
                                                            >
                                                                {
                                                                    regionalBranchItem.name
                                                                }
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </CustomSelect>
                                            </FormControl>
                                        </>
                                    )}
                                <FormControlLabel
                                    value={InsuranceOption.NOT_INSURED}
                                    control={<Radio />}
                                    label={prescriptionT('notInsured')}
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                className={classes.button}
                                variant={'contained'}
                                color={'secondary'}
                                disabled={isSendingDisabled}
                                onClick={() =>
                                    onSubmit({
                                        hasPublicInsurance:
                                            insuranceOption ===
                                            InsuranceOption.INSURED,
                                        nfzRegionalBranchCode:
                                            selectedRegionalBranchCode,
                                    })
                                }
                            >
                                {prescriptionT('sendDeclaration')}
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
export default PatientInsuranceNFZDeclaration;
