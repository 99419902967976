import QbCardCard from '../QBCardCard/QBCardCard';
import React, {useEffect, useState} from 'react';
import RegistrationCard from '../RegistrationCard/RegistrationCard';
import InfoCard from '../InfoCard/InfoCard';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import PriceCard from '../PriceCard/PriceCard';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {clearError, selectAuthUserData} from '../../../store/auth';
import {PATIENT, PHONE_NUMBER_CONFIRMATION} from '../../../const/auth';
import PhoneConfirmationCard from '../PhoneConfirmationCard/PhoneConfirmationCard';
import PurchaseCard from '../PurchaseCard/PurchaseCard';
import LoginCard from '../LoginCard/LoginCard';
import {ResellerCodeValidationDto} from '../../../types/subscriptions';

enum FormStageEnum {
    SimpleRegistration = 'SimpleRegistration',
    SMSConfirmation = 'SMSConfirmation',
    Purchase = 'Purchase',
    Login = 'Login',
}

type ResellerPackageProps = {
    individualPackageDraftId?: number;
    price?: number;
};

const initialVisibleCardsState = {
    infoCard: false,
    priceCard: false,
    phoneConfirmation: false,
    registration: false,
    purchase: false,
    login: false,
    resellerCodeCard: false,
};

const formStage = {
    [FormStageEnum.SimpleRegistration]: {
        ...initialVisibleCardsState,
        priceCard: true,
        registration: true,
    },
    [FormStageEnum.Login]: {
        ...initialVisibleCardsState,
        priceCard: true,
        login: true,
    },
    [FormStageEnum.SMSConfirmation]: {
        ...initialVisibleCardsState,
        priceCard: true,
        phoneConfirmation: true,
    },
    [FormStageEnum.Purchase]: {
        ...initialVisibleCardsState,
        priceCard: true,
        purchase: true,
    },
};
const OwnPackage = ({
    individualPackageDraftId,
    price,
}: ResellerPackageProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const [resellerData, setResellerData] =
        useState<ResellerCodeValidationDto | null>(null);
    const authUserData = useAppSelector(selectAuthUserData);
    const [visibleCards, setVisibleCards] = useState(initialVisibleCardsState);
    const [resellerCode, setResellerCode] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (authUserData?.role === PHONE_NUMBER_CONFIRMATION) {
            return setVisibleCards(formStage[FormStageEnum.SMSConfirmation]);
        }
        if (authUserData?.role === PATIENT) {
            return setVisibleCards(formStage[FormStageEnum.Purchase]);
        }

        return setVisibleCards(formStage[FormStageEnum.SimpleRegistration]);
    }, [resellerData, authUserData]);

    const handleLoginClick = () => {
        dispatch(clearError());
        setVisibleCards(formStage[FormStageEnum.Login]);
    };

    const handleRegisterClick = () => {
        dispatch(clearError());
        setVisibleCards(formStage[FormStageEnum.SimpleRegistration]);
    };

    const handleSuccessResellerCode = ({
        code,
        data,
    }: {
        code: string;
        data: ResellerCodeValidationDto;
    }) => {
        setResellerCode(code);
        setResellerData(data);
    };

    return (
        <div>
            {visibleCards.priceCard && <PriceCard price={price} />}
            {visibleCards.phoneConfirmation && <PhoneConfirmationCard />}
            {visibleCards.registration && (
                <RegistrationCard onLoginClick={handleLoginClick} />
            )}
            {visibleCards.login && (
                <LoginCard onRegisterClick={handleRegisterClick} />
            )}
            {visibleCards.purchase && (
                <PurchaseCard
                    individualPackageDraftId={individualPackageDraftId}
                    resellerCode={resellerCode}
                />
            )}
        </div>
    );
};

export default OwnPackage;
