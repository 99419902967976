import {
    Box,
    Card,
    CardContent,
    Grid,
    Hidden,
    MenuItem,
    Paper,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {AppointmentSettings, CustomSelect} from '../..';
import useStyles from './AppointmentDetailsStyles';
import clsx from 'clsx';
import {AppointmentPurchaseType} from '../../../const/appointmentPurchaseType';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {selectAuthUserData} from '../../../store/auth';
import {
    EMAIL_CONFIRMATION,
    NOT_CONFIRMED_USER,
    PHONE_NUMBER_CONFIRMATION,
} from '../../../const/auth';
import {PatientsChildDto} from '../../../types/children';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useTranslation} from 'react-i18next';
import MedicCardWithDetails from '../../common/MedicCardWithDetails/MedicCardWithDetails';
import {selectSelectedAppointment} from '../../../store/timeSlotsSlice';
import {isNotNullOrUndefined} from '../../../utils/lang';
import {getLanguage, getTranslation} from '../../../utils/translation';
import i18n from '../../../i18n/config';
import AddChildCard from '../../children/AddChildCard';
import {MemberDto} from '../../../types/patientPackages';
import React from 'react';

type AppointmentDetailsProps = {
    onSubmit?: (
        appointmentType: string,
        appointmentPurchaseType: AppointmentPurchaseType,
        addPatientToQueue: boolean,
        individualPackageDraftId?: number,
        patientGroupDraftId?: number,
    ) => void;
    patientChildren: PatientsChildDto[];
    selectedChildId?: number;
    isPediatric: boolean;
    isAddChildFormRequired: boolean;
    onChangeSelectedChild: (childId: number) => void;
    onChildSubmit: (data: MemberDto) => void;
    error?: string;
    isLoading: boolean;
    isSelectPatientCardRequired?: boolean;
};

const AppointmentDetails = ({
    onSubmit,
    patientChildren,
    onChangeSelectedChild,
    selectedChildId,
    isPediatric,
    isAddChildFormRequired,
    onChildSubmit,
    error,
    isLoading,
    isSelectPatientCardRequired,
}: AppointmentDetailsProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const authUserData = useAppSelector(selectAuthUserData);
    const notLoggedInUser = authUserData === null;
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const isUserAuthorized =
        authUserData &&
        authUserData?.role !== PHONE_NUMBER_CONFIRMATION &&
        authUserData.role !== EMAIL_CONFIRMATION &&
        authUserData.role !== NOT_CONFIRMED_USER;

    const selectedAppointment = useAppSelector(selectSelectedAppointment);
    const {briefMedic} = selectedAppointment;

    const currentLanguage = getLanguage(i18n?.language);

    const medicHasExtraDetails =
        briefMedic.education ||
        briefMedic.experience ||
        briefMedic.healsDiseases ||
        briefMedic.treatsPatientsInLanguages ||
        briefMedic.treatsPatientsOfTheAge;

    const medicExtraDetails = medicHasExtraDetails
        ? {
              education: getTranslation(
                  briefMedic.education,
                  briefMedic.translations,
                  currentLanguage,
                  'education',
              ),
              experience: getTranslation(
                  briefMedic.experience,
                  briefMedic.translations,
                  currentLanguage,
                  'experience',
              ),
              healsDiseases: getTranslation(
                  briefMedic.healsDiseases,
                  briefMedic.translations,
                  currentLanguage,
                  'healsDiseases',
              ),
              treatsPatientsInLanguages: getTranslation(
                  briefMedic.treatsPatientsInLanguages,
                  briefMedic.translations,
                  currentLanguage,
                  'treatsPatientsInLanguages',
              ),
              treatsPatientsOfTheAge: getTranslation(
                  briefMedic.treatsPatientsOfTheAge,
                  briefMedic.translations,
                  currentLanguage,
                  'treatsPatientsOfTheAge',
              ),
          }
        : null;

    const getSpecialtiesTranslation = () => {
        if (
            briefMedic &&
            isNotNullOrUndefined(briefMedic.specialtiesTranslations) &&
            briefMedic.specialtiesTranslations.length !== 0
        ) {
            const translation = briefMedic.specialtiesTranslations.find(o => {
                return o.languageCode === currentLanguage;
            });

            if (isNotNullOrUndefined(translation))
                return translation.specialties.join(', ');
        }

        return briefMedic.specialties.join(', ');
    };

    return (
        <Box
            bgcolor={'background.main'}
            className={clsx(classes.container, {
                [classes.containerDesktop]: !isMobile,
            })}
        >
            <Grid container spacing={1}>
                <Hidden smDown>
                    <Grid item xs={12}>
                        <Paper elevation={0}>
                            <MedicCardWithDetails
                                header={`${briefMedic.title} ${briefMedic.name} ${briefMedic.surname}`}
                                subheader={getSpecialtiesTranslation()}
                                reviewRate={briefMedic.reviewRate}
                                reviewCount={briefMedic.reviewCount}
                                description={getTranslation(
                                    briefMedic.bio,
                                    briefMedic.translations,
                                    currentLanguage,
                                    'bio',
                                )}
                                extraDetails={medicExtraDetails}
                                photoUrl={briefMedic.image}
                            />
                        </Paper>
                    </Grid>
                </Hidden>
                {authUserData?.role === NOT_CONFIRMED_USER && (
                    <Grid item xs={12}>
                        <Card elevation={0}>
                            <CardContent>
                                {t(
                                    'confirmedIdentityIsRequiredToPurchaseAnAppointment',
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {isSelectPatientCardRequired &&
                    isUserAuthorized &&
                    patientChildren.length > 0 && (
                        <Grid item xs={12}>
                            <Card elevation={0}>
                                <CardContent
                                    className={
                                        classes.patientSelectContentWrapper
                                    }
                                >
                                    <Grid container direction={'column'}>
                                        {commonT('patient')}
                                        <CustomSelect
                                            variant={'outlined'}
                                            value={selectedChildId}
                                            onChange={e =>
                                                onChangeSelectedChild(
                                                    e.target.value as number,
                                                )
                                            }
                                            style={{marginTop: 8}}
                                            fullWidth
                                        >
                                            {!isPediatric && (
                                                <MenuItem value={-1}>
                                                    {authUserData.displayName}
                                                </MenuItem>
                                            )}
                                            {patientChildren.map(child => (
                                                <MenuItem
                                                    key={child.id}
                                                    value={child.id}
                                                >
                                                    {child.name} {child.surname}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                {isUserAuthorized && isAddChildFormRequired && (
                    <Grid item xs={12}>
                        <Card elevation={0}>
                            <AddChildCard
                                onSubmit={onChildSubmit}
                                error={error}
                                isLoading={isLoading}
                            />
                        </Card>
                    </Grid>
                )}
                {(notLoggedInUser ||
                    authUserData.role !== NOT_CONFIRMED_USER) && (
                    <Grid item xs={12}>
                        <AppointmentSettings
                            onSubmit={onSubmit}
                            selectedChildId={selectedChildId}
                            disabled={isAddChildFormRequired}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default AppointmentDetails;
