import React from 'react';
import GoBackTile from '../../common/GoBackTile/GoBackTile';
import {
    Box,
    createStyles,
    Divider,
    Link,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';
import {InsertDriveFile} from '@material-ui/icons';

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            width: '100%',
        },
    }),
);

type IndividualPackageDetailsProps = {
    name: string;
    description?: string;
    imageUri?: string;
    text?: string;
    brochureUri?: string;
    isQbCard?: boolean;
    isUnilink?: boolean;
};
const IndividualPackageDetails = ({
    name,
    description,
    imageUri,
    text,
    brochureUri,
    isQbCard,
    isUnilink,
}: IndividualPackageDetailsProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const {getRoute} = useLandingPageUrl();

    const handleGoBack = () => {
        if (isQbCard) return (window.location.href = getRoute('QB_CARD'));
        if (isUnilink) return (window.location.href = getRoute('UNILINK'));

        return (window.location.href = getRoute('SUBSCRIPTIONS'));
    };

    return (
        <>
            <GoBackTile label={t('availablePackages')} onClick={handleGoBack} />

            <Divider />

            <Typography
                variant={'h1'}
                style={{fontSize: isDesktop ? 36 : 32, margin: '15px 0'}}
            >
                {name}
            </Typography>
            <Box style={{marginBottom: 15}}>{description}</Box>
            <img src={imageUri} className={classes.image} />
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
            {brochureUri && (
                <Link
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 50,
                        cursor: 'pointer',
                        marginBottom: 10,
                    }}
                    href={brochureUri}
                    target={'_blank'}
                >
                    <InsertDriveFile
                        fontSize={'large'}
                        style={{marginRight: 10}}
                    />
                    {t('brochureFile')}
                </Link>
            )}
        </>
    );
};
export default IndividualPackageDetails;
