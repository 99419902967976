import {Grid, IconButton, useMediaQuery, useTheme} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {isEmpty} from 'lodash';
import {DateTime} from 'luxon';
import React, {useCallback, useEffect, useState} from 'react';
import {generatePath, useHistory, useLocation} from 'react-router';
import {MedicsList, AppointmentDetails, Loading} from '../../components';
import Footer from '../../components/Layout/Footer/Footer';
import TopBar from '../../components/Layout/TopBar/TopBar';
import {
    EMPTY_TIME_SLOTS_PAGE,
    APPOINTMENT_PAYMENT_CONTINUE,
    ERROR,
} from '../../const/routes';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import useQuery from '../../hooks/useQuery';
import {
    fetchAppointments,
    getTimeSlotsToken,
    medicalServiceIdSpecified,
    selecttimeSlotsStatus,
    selectSelectedAppointment,
    selectTimeSlotsAppointments,
    timeSlotsStatusSpecified,
    selectSlotsInTimeWindow,
    selectAppointmentPurchaseOptions,
    fetchPurchaseOptions,
    selectIsChildrenRequired,
    selectAppointmentGroup,
} from '../../store/timeSlotsSlice';
import {selectedDateTimeSpecified} from '../../store/timeSlotsSearchSlice';
import {AppointmentPurchaseType} from '../../const/appointmentPurchaseType';
import timeSlotsProvider from '../../services/timeSlotsProvider';
import {MedicalServiceDto, TimeSlotReservationDto} from '../../types/timeSlot';
import NoSlotsInTimeWindowModal from '../../components/appointments/NoSlotsInTimeWindowModal/NoSlotsInTimeWindowModal';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import axios from 'axios';
import {isArray} from 'lodash-es';
import {PatientsChildDto} from '../../types/children';
import AccountProvider from '../../services/accountProvider';
import {CustomAlert} from '../../components/common/feedback';
import useMedicRedirect from '../../hooks/medic/useMedicRedirect';
import {selectAuthUserData} from '../../store/auth';
import {generatePaymentUrl} from '../../utils/timeSlots';
import {Helmet} from 'react-helmet-async';
import configuration from '../../configuration';
import useLandingPageUrl from '../../hooks/useLandingPageUrl';
import {IsNumberNotNan} from '../../utils/lang';
import {MemberDto} from '../../types/patientPackages';
import accountProvider from '../../services/accountProvider';
import {medicalServiceApi} from '../../api/medicalService/medicalServiceApi';
import {AppointmentGroupEnum} from '../../types/appointments';
import RegistrationCard from '../../components/individualPackage/RegistrationCard/RegistrationCard';

const FETCH_LOOP_INTERVAL = 1000;

const TimeSlotsPage = () => {
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const {t: tMetaDescription} = useTranslation(
        i18nNamespaces.META_DESCRIPTION,
    );
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    const status = useAppSelector(selecttimeSlotsStatus);
    const selectedAppointment = useAppSelector(selectSelectedAppointment);
    const appointments = useAppSelector(selectTimeSlotsAppointments);
    const slotsInTimeWindow = useAppSelector(selectSlotsInTimeWindow);
    const authUserData = useAppSelector(selectAuthUserData);
    const isChildrenRequired = useAppSelector(selectIsChildrenRequired);
    const purchaseOptions = useAppSelector(selectAppointmentPurchaseOptions);
    const appointmentGroup = useAppSelector(selectAppointmentGroup);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [loop, setLoop] = useState<NodeJS.Timeout>();
    const [modalLabel, setModalLabel] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [patientChildren, setPatientChildren] = useState<PatientsChildDto[]>(
        [],
    );
    const [selectedPatientId, setSelectedPatientId] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isAddChildFormRequired, setIsAddChildFormRequired] =
        useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [OMMedicalServices, setOMMedicalServices] = useState<
        MedicalServiceDto[]
    >([]);

    const medicalServiceId = parseInt(query.get('medicalServiceId'));
    const startDateTime = DateTime.fromISO(query.get('startDateTime'));
    const endDateTime = DateTime.fromISO(query.get('endDateTime'));
    const appointmentId = parseInt(query.get('appointmentId'));
    const medicId = query.get('medicId');
    const referralId = parseInt(query.get('referralId'));
    const childIdFromQuery = parseInt(query.get('childId'));
    const occupationalMedicineReferralId = query.has(
        'occupationalMedicineReferralId',
    )
        ? parseInt(query.get('occupationalMedicineReferralId'))
        : null;

    const {getRoute} = useLandingPageUrl();

    useMedicRedirect();

    useEffect(() => {
        // Select child if child id is specified in query and exists in patient account
        if (
            IsNumberNotNan(childIdFromQuery) &&
            patientChildren.some(child => child.id === childIdFromQuery)
        ) {
            return setSelectedPatientId(childIdFromQuery);
        }
        // Show add child form if patient doesn't have children and child is required
        if (
            patientChildren.length === 0 &&
            appointmentGroup === AppointmentGroupEnum.PediatricAppointment
        ) {
            return setIsAddChildFormRequired(true);
        }

        // Select patient children if patient has children and child is required
        if (
            appointmentGroup === AppointmentGroupEnum.PediatricAppointment &&
            patientChildren.length > 0
        ) {
            return setSelectedPatientId(patientChildren[0].id);
        }

        // Select first patient when child isn't required
        if (appointmentGroup !== AppointmentGroupEnum.PediatricAppointment) {
            return setSelectedPatientId(-1);
        }
    }, [childIdFromQuery, appointmentGroup, patientChildren]);

    const fetchTimeSlotsToken = useCallback(() => {
        const timeSlotId = query.get('timeSlotId');
        dispatch(
            selectedDateTimeSpecified({
                startDateTime,
                endDateTime,
            }),
        );
        dispatch(medicalServiceIdSpecified(medicalServiceId));
        if (authUserData)
            // If user is logged in - fetch token for replace or set appointment from referral
            dispatch(
                getTimeSlotsToken({
                    timeSlotId,
                    medicId,
                }),
            );
        else dispatch(getTimeSlotsToken({}));
    }, [dispatch, startDateTime, endDateTime]);

    // When there is no time slot with this id, redirect to page with 5 time slots from date
    const onTimeSlotSearchFail = useCallback(() => {
        query.delete('timeSlotId');
        history.replace({search: query.toString()});
        fetchTimeSlotsToken();
        setModalLabel(t('timeSlotAlreadyReserved'));
        setShowModal(true);
    }, [query, history, fetchTimeSlotsToken]);

    // When appointment is not available, redirect to page with 5 time slots from date
    const onAppointmentIdSearchFail = useCallback(() => {
        query.delete('appointmentId');
        history.replace({search: query.toString()});
        setModalLabel(t('changeOfAppointmentIsNotPossible'));
        setShowModal(true);
        fetchTimeSlotsToken();
    }, [query, history, fetchTimeSlotsToken]);

    const fetchOMMedicalServices = async () => {
        try {
            const {data} =
                await medicalServiceApi.getOccupationalMedicineMedicalServices();
            setOMMedicalServices(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchTimeSlotsToken();
        if (authUserData) fetchOMMedicalServices();
    }, [authUserData]);

    useEffect(() => {
        // Status 'saved' means token is acquired and 'failed' means there is no response yet
        if (status === 'saved' || status === 'failed') {
            // Search with timeSlotId param has failed
            if (status === 'failed' && query.has('timeSlotId')) {
                onTimeSlotSearchFail();
            }

            if (status === 'failed' && query.has('appointmentId')) {
                onAppointmentIdSearchFail();
            }

            setLoop(
                setTimeout(() => {
                    if (authUserData)
                        dispatch(
                            fetchAppointments({
                                medicalServiceId,
                                appointmentId,
                                referralId,
                                occupationalMedicineReferralId,
                            }),
                        );
                    else
                        dispatch(
                            fetchAppointments({
                                medicalServiceId,
                            }),
                        );
                }, FETCH_LOOP_INTERVAL),
            );
        } else if (status === 'loaded') {
            if (isEmpty(appointments)) {
                history.push(EMPTY_TIME_SLOTS_PAGE, {
                    medicalServiceId,
                    startDateTime,
                    endDateTime,
                });
                dispatch(timeSlotsStatusSpecified('idle'));
            } else if (isEmpty(purchaseOptions)) {
                onAppointmentIdSearchFail();
            }
        }
        return () => clearInterval(loop);
    }, [
        status,
        dispatch,
        appointments,
        history,
        slotsInTimeWindow,
        medicalServiceId,
        purchaseOptions,
    ]);

    // Returns reservation result or information about already reserved timeSlot
    const getTimeSlotReservationResult = async (token: string) => {
        try {
            return await timeSlotsProvider.getTimeSlotReservationResult(token);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // TimeSlot is already reserved
                if (
                    isArray(error.response.data) &&
                    error.response.data.includes('TimeSlotIsNotAvailable')
                ) {
                    await dispatch(timeSlotsStatusSpecified('idle'));
                    onTimeSlotSearchFail();
                    return;
                } else {
                    // Status is not-found, continue polling
                    if (error.response.status === 404) {
                        return null;
                    }
                    // Other status, show error
                    if (error.response.status !== 400) {
                        history.push(ERROR);
                        return;
                    }
                }
            }
        }
    };

    const isTimeSlotOM = (timeSlotReservation: TimeSlotReservationDto) =>
        OMMedicalServices?.some(
            service => service.id === timeSlotReservation.medicalServiceId,
        );

    const reserveTimeSlot = async (
        timeSlotId: string,
        timeSlotReservation: TimeSlotReservationDto,
    ) => {
        dispatch(timeSlotsStatusSpecified('loading'));

        let token;
        if (appointmentId) {
            // Change appointment by appointmentId
            token = await timeSlotsProvider.reserveTimeSlot(timeSlotId, {
                ...timeSlotReservation,
                timeWindow: null,
                appointmentId,
            });
        } else {
            // Set appointment
            token = await timeSlotsProvider.reserveTimeSlot(
                timeSlotId,
                timeSlotReservation,
            );
        }

        let timeSlotReservationResult = await getTimeSlotReservationResult(
            token,
        );

        while (timeSlotReservationResult === null) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            timeSlotReservationResult = await getTimeSlotReservationResult(
                token,
            );
        }

        if (timeSlotReservationResult) {
            if (timeSlotReservationResult.createdAppointmentId) {
                history.push(
                    generatePath(APPOINTMENT_PAYMENT_CONTINUE, {
                        appointmentId:
                            timeSlotReservationResult.createdAppointmentId,
                    }),
                    {
                        freeAppointment: true,
                        withoutSurvey:
                            isTimeSlotOM(timeSlotReservation) || appointmentId,
                    },
                );
            } else {
                history.push(
                    generatePaymentUrl(
                        timeSlotId,
                        timeSlotReservation.appointmentType,
                        timeSlotReservation.appointmentPaymentType,
                        timeSlotReservation.individualPackageDraftId,
                        timeSlotReservation.patientGroupDraftId,
                        timeSlotReservation.timeWindow,
                        location.pathname + location.search,
                        timeSlotReservation.childId,
                        timeSlotReservation.referralId,
                        occupationalMedicineReferralId,
                    ),
                );
            }
        }
    };

    const onSubmit = (
        appointmentType: string,
        appointmentPurchaseType: AppointmentPurchaseType,
        addPatientToQueue: boolean,
        individualPackageDraftId?: number,
        patientGroupDraftId?: number,
    ) => {
        if (appointmentPurchaseType === 'IndividualPackageOffer') {
            window.location.href = getRoute('INDIVIDUAL_PACKAGES');
            return;
        }

        const {id} = selectedAppointment;

        const timeWindow = addPatientToQueue
            ? {
                  startDate: query.get('startDateTime'),
                  endDate: query.get('endDateTime'),
              }
            : undefined;

        reserveTimeSlot(id, {
            appointmentPaymentType: appointmentPurchaseType,
            appointmentType,
            medicalServiceId,
            individualPackageDraftId,
            patientGroupDraftId,
            timeWindow,
            appointmentId,
            childId: selectedPatientId === -1 ? null : selectedPatientId,
            referralId,
            occupationalMedicineReferralId,
        });
    };

    const fetchPatientChildren = async () => {
        const {data} = await AccountProvider.getPatientChildren();
        setPatientChildren(data);
    };

    useEffect(() => {
        if (authUserData?.role !== 'Patient') return;

        setErrorMessage('');
        try {
            fetchPatientChildren();
        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error))
                setErrorMessage(error.response.data[0]);
        }
    }, [authUserData]);

    const onChangeSelectedChild = (_childId: number) => {
        setSelectedPatientId(_childId);
        dispatch(
            fetchPurchaseOptions({
                medicalServiceId,
                childId: _childId === -1 ? null : _childId,
                referralId: referralId,
            }),
        );
    };

    const handleAddChild = async (child: MemberDto) => {
        try {
            setLoading(true);
            setError('');
            await accountProvider.registerPatientChild(child);
            await fetchPatientChildren();
        } catch (error) {
            console.error(error);
            setError(tCommon('errorWhileAddChild'));
        } finally {
            setLoading(false);
        }
    };

    return status === 'loaded' && !isEmpty(appointments) ? (
        <>
            {configuration.environment === 'Production' && (
                <Helmet>
                    <title>{tMetaDescription('mainPageTitle')}</title>
                    <meta
                        name="description"
                        content={tMetaDescription('mainPageDescription')}
                    />
                    <meta
                        name="keywords"
                        content={tMetaDescription('mainPageKeywords')}
                    />
                </Helmet>
            )}

            {errorMessage && (
                <CustomAlert severity="error" message={errorMessage} />
            )}
            <NoSlotsInTimeWindowModal
                showModal={showModal}
                label={modalLabel}
                onButtonClick={() => setShowModal(false)}
            />
            <TopBar>
                {isMobile && isSelected && (
                    <IconButton onClick={() => setIsSelected(false)}>
                        <Close />
                    </IconButton>
                )}
            </TopBar>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <MedicsList
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    {(!isMobile || isSelected) && (
                        <AppointmentDetails
                            onSubmit={onSubmit}
                            patientChildren={patientChildren}
                            onChangeSelectedChild={onChangeSelectedChild}
                            selectedChildId={selectedPatientId}
                            isPediatric={
                                appointmentGroup ===
                                AppointmentGroupEnum.PediatricAppointment
                            }
                            isAddChildFormRequired={isAddChildFormRequired}
                            isSelectPatientCardRequired={
                                appointmentGroup !==
                                    AppointmentGroupEnum.OccupationalMedicineAppointment &&
                                appointmentGroup !==
                                    AppointmentGroupEnum.AdultAppointment
                            }
                            onChildSubmit={handleAddChild}
                            error={error}
                            isLoading={loading}
                        />
                    )}
                </Grid>
            </Grid>
            <Footer />
        </>
    ) : (
        <Loading withBackdrop loading />
    );
};

export default TimeSlotsPage;
