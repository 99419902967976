import {
    Box,
    Button,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    LOGIN_PAGE,
    REGISTER_PAGE,
    TIME_SLOTS_PAGE,
} from '../../../const/routes';
import {desktop} from '../../../const/sizes';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectEndDateTime,
    selectStartDateTime,
} from '../../../store/timeSlotsSearchSlice';
import {
    selectAppointmentPurchaseOptions,
    selectIsChildrenRequired,
    selectMedicalServiceId,
} from '../../../store/timeSlotsSlice';
import {parseTimeSlotQuery} from '../../../utils/timeSlots';
import AppointmentAnonymousPurchaseOption from './AppointmentAnonymousPurchaseOption/AppointmentAnonymousPurchaseOption';
import RegistrationCard from '../../individualPackage/RegistrationCard/RegistrationCard';
import React from 'react';

const AppointmentAnonymousPurchaseOptions = () => {
    const purchaseOptions = useAppSelector(selectAppointmentPurchaseOptions);
    const history = useHistory();
    const medicalServiceId = useAppSelector(selectMedicalServiceId);
    const startDateTime = useAppSelector(selectStartDateTime);
    const endDateTime = useAppSelector(selectEndDateTime);
    const isChildrenRequired = useAppSelector(selectIsChildrenRequired);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const handleClickSignIn = () => {
        const returnUrlString =
            '?returnUrl=' +
            encodeURIComponent(
                TIME_SLOTS_PAGE +
                    parseTimeSlotQuery({
                        medicalServiceId,
                        startDateTime,
                        endDateTime,
                    }),
            );
        const loginTypeString = '&login_type=LOGIN';
        history.push({
            pathname: LOGIN_PAGE,
            search: returnUrlString + loginTypeString,
        });
    };

    const handleClickCreateAccount = () => {
        const returnUrlString =
            '?returnUrl=' +
            encodeURIComponent(
                TIME_SLOTS_PAGE +
                    parseTimeSlotQuery({
                        medicalServiceId,
                        startDateTime,
                        endDateTime,
                    }),
            );
        history.push({
            pathname: REGISTER_PAGE,
            search:
                returnUrlString + '&isChildrenRequired=' + isChildrenRequired,
        });
    };

    const useStyles = makeStyles(() =>
        createStyles({
            packetPrice: {
                fontWeight: 'bold',
            },
            button: {
                marginTop: desktop.contentSmallerSpacing,
                fontWeight: 'bold',
            },
            additionalInfo: {
                marginTop: desktop.contentSmallerSpacing,
            },
        }),
    );

    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);

    return (
        <>
            {/*<Box my={1}>{t('appointmentTypesDescription')}</Box>*/}
            {/*{purchaseOptions.individualPackageOffer && (*/}
            {/*    <AppointmentAnonymousPurchaseOption*/}
            {/*        price={purchaseOptions.individualPackageOffer.price}*/}
            {/*    >*/}
            {/*        {t('withSubscriptionFor')}{' '}*/}
            {/*        <span className={classes.packetPrice}>*/}
            {/*            {purchaseOptions.individualPackageOffer.packagePrice} zł*/}
            {/*            /{t('perMonthShort')}*/}
            {/*        </span>*/}
            {/*    </AppointmentAnonymousPurchaseOption>*/}
            {/*)}*/}

            <AppointmentAnonymousPurchaseOption
                price={purchaseOptions.oneTimePrice}
            >
                {t('appointmentPrice')}
            </AppointmentAnonymousPurchaseOption>
            {/*<Box mt={1} style={{fontWeight: 'bold'}}>*/}
            {/*    {t('createAccountOrLogInToCreateAnAppointment')}*/}
            {/*</Box>*/}
            {/*<Grid*/}
            {/*    container*/}
            {/*    spacing={isDesktop ? 1 : 0}*/}
            {/*    direction={isDesktop ? 'row-reverse' : 'row'}*/}
            {/*>*/}
            {/*    <Grid item xs={12}>*/}
            {/*<Button*/}
            {/*    variant={'contained'}*/}
            {/*    color={'secondary'}*/}
            {/*    onClick={handleClickCreateAccount}*/}
            {/*    className={classes.button}*/}
            {/*    fullWidth*/}
            {/*>*/}
            {/*    {t('book')}*/}
            {/*</Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            {/*<Box className={classes.additionalInfo}>*/}
            {/*    {t('searchResultsNote')}*/}
            {/*</Box>*/}
        </>
    );
};

export default AppointmentAnonymousPurchaseOptions;
