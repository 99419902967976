import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
} from '@material-ui/core';
import useStyles from './PatientConfirmIdentityComponentStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '../../common/Accordion/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useFormik} from 'formik';
import * as yup from 'yup';
import CustomTextField from '../../common/CustomTextField/CustomTextField';
import Label from '../../common/Label/Label';
import {MojeIDLogo} from '../../../assets';
import {ConfirmIdentitySimplyDto} from '../../../types/Login';
import {useEffect, useState} from 'react';
import {ErrorOutline} from '@material-ui/icons';
import {isMobile} from 'react-device-detect';

interface Props {
    onSubmit: (data: ConfirmIdentitySimplyDto) => void;
    onConfirmWithMojeID: () => void;
    error: string;
    defaultExpanded?: boolean;
}

const REQUIRED_STRING = 'requiredField';

const PatientConfirmIdentityComponent = ({
    onSubmit,
    onConfirmWithMojeID,
    error,
    defaultExpanded,
}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.ACCOUNT);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);

    const [showAlert, setShowAlert] = useState<boolean>(true);

    const peselValidation = yup
        .string()
        .matches(/^[0-9]+$/, authT('peselShouldContainOnlyNumbers'))
        .length(11, authT('lengthShouldBe11chars'));

    const {
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        handleSubmit,
    } = useFormik({
        initialValues: {
            name: '',
            secondName: '',
            surname: '',
            pesel: '',
        },
        validationSchema: yup.object({
            name: yup.string().required(t(REQUIRED_STRING)),
            surname: yup.string().required(t(REQUIRED_STRING)),
            pesel: peselValidation.required(REQUIRED_STRING),
        }),
        onSubmit: onSubmit,
    });

    return (
        <Box className={classes.section}>
            <Accordion
                square
                defaultExpanded={defaultExpanded || false}
                onChange={(e, expanded) => {
                    setShowAlert(!expanded);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('confirmIdentity')}
                        </h4>
                        {showAlert && (
                            <ErrorOutline className={classes.alertIcon} />
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit}>
                        <Grid
                            className={classes.container}
                            container
                            spacing={1}
                        >
                            <Grid item>{t('confirmIdentityRequired')}</Grid>
                            <Grid item className={classes.gridItem}>
                                {t('enterPersonalDataOrConfirmWith')}
                                <img
                                    src={MojeIDLogo}
                                    alt="Moje ID"
                                    className={classes.mojeIDLogo}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="name">
                                        {authT('name')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        helperText={
                                            touched.name && authT(errors.name)
                                        }
                                        error={touched.name && !!errors.name}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="surname">
                                        {authT('surname')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="surname"
                                        name="surname"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.surname}
                                        helperText={
                                            touched.surname &&
                                            authT(errors.surname)
                                        }
                                        error={
                                            touched.surname && !!errors.surname
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="pesel">
                                        {authT('Pesel')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="pesel"
                                        name="pesel"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.pesel}
                                        helperText={
                                            touched.pesel && authT(errors.pesel)
                                        }
                                        error={touched.pesel && !!errors.pesel}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                            {error && (
                                <Grid item xs={12} className={classes.error}>
                                    {error}
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                {isMobile ? (
                                    <Button
                                        className={classes.button}
                                        variant={'contained'}
                                        color={'secondary'}
                                        type={'submit'}
                                    >
                                        {t('save')}
                                    </Button>
                                ) : (
                                    <Button
                                        className={classes.button}
                                        variant={'outlined'}
                                        color={'secondary'}
                                        onClick={onConfirmWithMojeID}
                                    >
                                        {t('confirmByMojeID')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {isMobile ? (
                                    <Button
                                        className={classes.button}
                                        variant={'outlined'}
                                        color={'secondary'}
                                        onClick={onConfirmWithMojeID}
                                    >
                                        {t('confirmByMojeID')}
                                    </Button>
                                ) : (
                                    <Button
                                        className={classes.button}
                                        variant={'contained'}
                                        color={'secondary'}
                                        type={'submit'}
                                    >
                                        {t('save')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default PatientConfirmIdentityComponent;
