import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {WHITE} from '../../const/colors';
import {desktop, mobile} from '../../const/sizes';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: WHITE,
        width: '100%',
        padding: mobile.contentLargerSpacing,
    },
    containerDesktop: {
        minHeight: `calc(100vh - ${theme.spacing(7)}px)`,
        flexGrow: 1,
    },
    childrenTileHeader: {
        fontSize: 20,
        fontWeight: 700,
    },
    input: {
        marginTop: 10,
    },
    buttonRow: {marginTop: 20},
    addButton: {width: '100%'},
    error: {
        marginTop: 10,
        color: 'red',
    },
    [theme.breakpoints.up('md')]: {
        buttonRow: {marginTop: 24, justifyContent: 'flex-end'},
        container: {
            padding: desktop.contentSmallerSpacing,
        },
    },
}));

export default useStyles;
