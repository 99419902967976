import {Box, Card} from '@material-ui/core';
import React from 'react';
import {desktop} from '../../../const/sizes';

type InfoCardProps = {
    title: string;
    children: React.ReactNode | string;
};
const InfoCard = ({title, children}: InfoCardProps) => (
    <Card
        elevation={0}
        style={{
            padding: desktop.contentSmallerSpacing,
            marginBottom: desktop.contentSmallerSpacing,
        }}
    >
        <Box
            style={{
                fontWeight: 'bold',
                marginBottom: 8,
            }}
        >
            {title}
        </Box>
        <Box>{children}</Box>
    </Card>
);

export default InfoCard;
