import {useCallback, useMemo, useState} from 'react';
import {
    Box,
    IconButton,
    Link,
    List,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import useStyles from './MedicsListStyles';
import {TimeSlot} from '../../../types/timeSlot';
import {GoBackIcon} from '../../../assets';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectSelectedAppointment,
    selectTimeSlotsAppointments,
    selectedAppointmentSpecified,
    selectMedicalServiceName,
    selectSlotsInTimeWindow,
    selectMedicalServiceTranslations,
} from '../../../store/timeSlotsSlice';
import MedicItem from './MedicItem/MedicItem';
import {getRelativeDay} from '../../../utils/date';
import {useTranslation} from 'react-i18next';
import {
    selectEndDateTime,
    selectStartDateTime,
} from '../../../store/timeSlotsSearchSlice';
import i18nNamespaces from '../../../const/i18nNamespaces';
import AddPatientToQueueOnPageLeaveModal from '../AddPatientToQueueOnPageLeaveModal/AddPatientToQueueOnPageLeaveModal';
import {selectAuthUserData} from '../../../store/auth';
import {isNotNullOrUndefined} from '../../../utils/lang';
import {getLanguage, getTranslation} from '../../../utils/translation';
import i18n from '../../../i18n/config';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';
import TextClip from '../../common/TextClip/TextClip';

type Props = {
    isSelected?: boolean;
    setIsSelected?: (value: boolean) => void;
};

const MedicsList = ({isSelected, setIsSelected}: Props) => {
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {t: tMedic} = useTranslation(i18nNamespaces.MEDIC);
    const currentLanguage = getLanguage(i18n?.language);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const startDateTime = useAppSelector(selectStartDateTime);
    const endDateTime = useAppSelector(selectEndDateTime);
    const appointments = useAppSelector(selectTimeSlotsAppointments);
    const selectedAppointment = useAppSelector(selectSelectedAppointment);
    const {briefMedic} = selectedAppointment;
    const medicalServiceName = useAppSelector(selectMedicalServiceName);
    const medicalServiceTranslations = useAppSelector(
        selectMedicalServiceTranslations,
    );
    const slotsInTimeWindow = useAppSelector(selectSlotsInTimeWindow);
    const authUserData = useAppSelector(selectAuthUserData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [onGoBackModalVisibility, setOnGoBackModalVisibility] =
        useState(false);
    const notLoggedInUser = !authUserData;
    const {getRoute} = useLandingPageUrl();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const extraDetails: Record<string, string> = useMemo(
        () => ({
            education: getTranslation(
                briefMedic.education,
                briefMedic.translations,
                currentLanguage,
                'education',
            ),
            experience: getTranslation(
                briefMedic.experience,
                briefMedic.translations,
                currentLanguage,
                'experience',
            ),
            healsDiseases: getTranslation(
                briefMedic.healsDiseases,
                briefMedic.translations,
                currentLanguage,
                'healsDiseases',
            ),
            treatsPatientsInLanguages: getTranslation(
                briefMedic.treatsPatientsInLanguages,
                briefMedic.translations,
                currentLanguage,
                'treatsPatientsInLanguages',
            ),
            treatsPatientsOfTheAge: getTranslation(
                briefMedic.treatsPatientsOfTheAge,
                briefMedic.translations,
                currentLanguage,
                'treatsPatientsOfTheAge',
            ),
        }),
        [currentLanguage, briefMedic],
    );

    const extraDetailsHaveContent = useMemo(
        () => Object.values(extraDetails).some(value => value?.length),
        [extraDetails],
    );

    const onSelectEvent = (appointment: TimeSlot) => {
        dispatch(selectedAppointmentSpecified({appointmentId: appointment.id}));
        setIsSelected(true);
        window.scrollTo(0, 0);
    };

    const mapAppointments = appointments?.map((appointment: TimeSlot) => (
        <MedicItem
            key={appointment.id}
            item={appointment}
            onClick={() => onSelectEvent(appointment)}
            showBookButton
        />
    ));

    const goBackClickHandler = () => {
        return slotsInTimeWindow || notLoggedInUser
            ? goBackToPreviousPage()
            : setOnGoBackModalVisibility(true);
    };

    const goBackToPreviousPage = () => {
        window.location.href = getRoute('SEARCH_FOR_A_DOCTOR');
    };

    const toggleShowDetails = () => {
        setShowDetails(prev => !prev);
    };

    const medicDetails = useCallback(
        () =>
            Object.keys(extraDetails).map(
                key =>
                    isNotNullOrUndefined(extraDetails[key]) &&
                    extraDetails[key] != '' && (
                        <Box key={key}>
                            <Box className={classes.extraDetailsTitle}>
                                {tMedic(key)}
                            </Box>
                            <TextClip text={extraDetails[key]} />
                        </Box>
                    ),
            ),
        [extraDetails],
    );

    return (
        <>
            <Box bgcolor={'white'}>
                {(!isSelected || !isMobile) && (
                    <Box
                        className={classes.goBackWrapper}
                        onClick={goBackClickHandler}
                    >
                        <IconButton className={classes.goBackButton}>
                            <img src={GoBackIcon} alt="Go back" />
                        </IconButton>
                        <Box>
                            <Box>
                                {getTranslation(
                                    medicalServiceName,
                                    medicalServiceTranslations,
                                    currentLanguage,
                                    'name',
                                )}{' '}
                                - {t('closestAvailableTerm')}
                            </Box>
                            {startDateTime.isValid && endDateTime.isValid && (
                                <Box className={classes.preferredTerm}>
                                    {t('preferredDate')}:{' '}
                                    {getRelativeDay(startDateTime, 'd.MM')},{' '}
                                    {currentLanguage === 'EN'
                                        ? startDateTime.toFormat('hh:mm a')
                                        : startDateTime.toFormat('HH:mm')}
                                    -{' '}
                                    {currentLanguage === 'EN'
                                        ? endDateTime.toFormat('hh:mm a')
                                        : endDateTime.toFormat('HH:mm')}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                {isMobile && isSelected ? (
                    <>
                        <MedicItem item={selectedAppointment} />
                        <Box p={1} pb={1.5}>
                            {selectedAppointment.briefMedic.bio}
                            {extraDetailsHaveContent && (
                                <>
                                    {showDetails && <Box>{medicDetails()}</Box>}
                                    <Box mt={1}>
                                        <Link
                                            color={'secondary'}
                                            style={{cursor: 'pointer'}}
                                            onClick={toggleShowDetails}
                                        >
                                            {showDetails
                                                ? t('hideDetails')
                                                : t('showDetails')}
                                        </Link>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </>
                ) : (
                    <List style={{padding: 0}}>
                        {appointments && mapAppointments}
                    </List>
                )}
            </Box>
            <AddPatientToQueueOnPageLeaveModal
                open={onGoBackModalVisibility}
                goBackToPreviousPage={goBackToPreviousPage}
            />
        </>
    );
};

export default MedicsList;
