import React, {useContext, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Grid,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import FormikTextField from '../../common/form/FormikTextField/FormikTextField';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {individualPackageApi} from '../../../api/individualPackage/individualPackageApi';
import {AlertContext} from '../../../contexts/AlertContext';
import axios from 'axios';
import {QbCardOwner} from '../../../types/individualPackages';
import configuration from '../../../configuration';
import {Loading} from '../../index';
import {NewTabLink} from '../../common/navigation';
import FormMessage from '../../common/form/FormMessage/FormMessage';
import {desktop, mobile} from '../../../const/sizes';

const REQUIRED_STRING = 'requiredField';

type QBCardCardProps = {
    onSuccess: (data: QbCardOwner) => void;
    qbServiceId: string;
};
const QbCardCard = ({onSuccess, qbServiceId}: QBCardCardProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const {palette} = useTheme();
    const formik = useFormik({
        initialValues: {
            cardNumber: '',
            pinCode: '',
        },
        validationSchema: yup.object({
            cardNumber: yup.string().required(commonT(REQUIRED_STRING)),
            pinCode: yup
                .string()
                .min(4, commonT('minCharacters', {minChars: 4}))
                .required(commonT(REQUIRED_STRING)),
        }),
        onSubmit: async values => {
            try {
                setLoading(true);
                const {data} = await individualPackageApi.validateQbCard({
                    ...values,
                    qbServiceId,
                });
                onSuccess({
                    name: data.name,
                    surname: data.surname,
                    cardNumber: values.cardNumber,
                    email: data.email,
                });
            } catch (error) {
                console.error(error);
                if (axios.isAxiosError(error))
                    setErrorMessage(t(error.response?.data[0]));
            } finally {
                setLoading(false);
            }
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Loading loading={loading} withBackdrop />
            <Card
                elevation={0}
                style={{
                    padding: desktop.contentSmallerSpacing,
                    marginBottom: desktop.contentSmallerSpacing,
                }}
            >
                <Box
                    style={{
                        fontWeight: 'bold',
                        marginBottom: 8,
                    }}
                >
                    {t('packageAvailableOnlyForQBCardOwners')}
                </Box>
                <Box style={{marginBottom: desktop.contentSmallerSpacing}}>
                    {t('youDontHaveAUniformCard')}{' '}
                    <NewTabLink
                        color={palette.secondary.main}
                        href={configuration?.qbCardApplyUrl}
                    >
                        {t('apply')}
                    </NewTabLink>
                </Box>
                <Grid container spacing={isDesktop ? 2 : 1}>
                    <Grid item sm={6} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={t('cardNumber') + ' *'}
                            fieldName={'cardNumber'}
                            autoComplete="new-password"
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <input
                        autoComplete="new-password"
                        name="hidden"
                        type="text"
                        style={{display: 'none'}}
                    />
                    <Grid item sm={6} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={t('cardPIN') + ' *'}
                            fieldName={'pinCode'}
                            type={'password'}
                            autoComplete="new-password"
                            noStyles
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <FormMessage
                    message={errorMessage}
                    mt={desktop.contentSmallerSpacing}
                />
                <Button
                    style={{
                        marginTop: desktop.contentSmallerSpacing,
                        fontWeight: 'bold',
                    }}
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    fullWidth
                >
                    {t('getAccess')}
                </Button>
            </Card>
        </form>
    );
};

export default QbCardCard;
