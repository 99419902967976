import {Box, Button, FormGroup, Grid} from '@material-ui/core';
import calendarSectionUseStyles from '../../appointmentsCalendar/appointmentsCalendarSectionStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {Formik, useFormik} from 'formik';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';
import accountProvider from '../../../services/accountProvider';
import useStyles from './PatientAddressComponentStyles';
import CustomTextField from '../../common/CustomTextField/CustomTextField';
import Label from '../../common/Label/Label';
import auth from '../../../store/auth';
import {isNotNullOrUndefined} from '../../../utils/lang';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '../../common/Accordion/Accordion';
import {ErrorOutline} from '@material-ui/icons';
import {MAGENTA, RED} from '../../../const/colors';

type ChangeAddressFormValues = {
    streetName: string;
    houseNumber: string;
    apartmentNumber: string;
    postcode: string;
    cityName: string;
};

const REQUIRED_STRING = 'requiredField';

interface Props {
    onSubmit: (data: ChangeAddressFormValues) => void;
    defaultExpanded?: boolean;
}
const PatientAddressComponent = ({onSubmit, defaultExpanded}: Props) => {
    const classes = useStyles();

    const {t} = useTranslation(i18nNamespaces.ACCOUNT);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);

    const [showAlert, setShowAlert] = useState<boolean>(true);

    const {
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        handleSubmit,
    } = useFormik({
        initialValues: {
            streetName: '',
            houseNumber: '',
            apartmentNumber: '',
            postcode: '',
            cityName: '',
        },
        validationSchema: yup.object({
            streetName: yup.string().required(t(REQUIRED_STRING)),
            houseNumber: yup.string().required(t(REQUIRED_STRING)),
            postcode: yup.string().required(t(REQUIRED_STRING)),
            cityName: yup.string().required(t(REQUIRED_STRING)),
        }),
        onSubmit: onSubmit,
    });

    return (
        <Box className={classes.section}>
            <Accordion
                square
                defaultExpanded={defaultExpanded || false}
                onChange={(e, expanded) => {
                    setShowAlert(!expanded);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={classes.titleContainer}>
                        <h4 className={classes.sectionHeader}>
                            {t('address')}
                        </h4>
                        {showAlert && (
                            <ErrorOutline className={classes.alertIcon} />
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit}>
                        <Grid
                            className={classes.container}
                            container
                            spacing={1}
                        >
                            <Grid item xs={12}>
                                <Box>{t('addressRequiredReason')}</Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="streetName">
                                        {authT('street')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="streetName"
                                        name="streetName"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.streetName}
                                        helperText={
                                            touched.streetName &&
                                            authT(errors.streetName)
                                        }
                                        error={
                                            touched.streetName &&
                                            !!errors.streetName
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="houseNumber">
                                        {authT('houseNumber')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="houseNumber"
                                        name="houseNumber"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.houseNumber}
                                        helperText={
                                            touched.houseNumber &&
                                            authT(errors.houseNumber)
                                        }
                                        error={
                                            touched.houseNumber &&
                                            !!errors.houseNumber
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="apartmentNumber">
                                        {authT('apartmentNumber')}
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="apartmentNumber"
                                        name="apartmentNumber"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.apartmentNumber}
                                        helperText={
                                            touched.apartmentNumber &&
                                            authT(errors.apartmentNumber)
                                        }
                                        error={
                                            touched.apartmentNumber &&
                                            !!errors.apartmentNumber
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="postcode">
                                        {authT('postcode')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="postcode"
                                        name="postcode"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.postcode}
                                        helperText={
                                            touched.postcode &&
                                            authT(errors.postcode)
                                        }
                                        error={
                                            touched.postcode &&
                                            !!errors.postcode
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormGroup>
                                    <Label htmlFor="cityName">
                                        {authT('cityName')}*
                                    </Label>
                                    <CustomTextField
                                        className={classes.input}
                                        id="cityName"
                                        name="cityName"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.cityName}
                                        helperText={
                                            touched.cityName &&
                                            authT(errors.cityName)
                                        }
                                        error={
                                            touched.cityName &&
                                            !!errors.cityName
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid
                                item
                                className={classes.buttonContainer}
                                md={6}
                                xs={12}
                            >
                                <Button
                                    className={classes.button}
                                    type={'submit'}
                                    variant={'contained'}
                                    color={'secondary'}
                                >
                                    {commonT('save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default PatientAddressComponent;
