import {http, rawHttp} from '../../services/http';
import {MedicalServicePurchaseOptionsDto} from '../../types/medicalServicePurchaseOptions';
import {
    getPurchaseOptionsEndpoint,
    OCCUPATIONAL_MEDICINE_MEDICAL_SERVICES,
} from './medicalServiceEndpoints';
import {MedicalServiceDto} from '../../types/timeSlot';

class MedicalServiceApi {
    getOccupationalMedicinePurchaseOptions(
        medicalServiceId: number,
        occupationalMedicineReferralId?: number,
    ) {
        return http.get<MedicalServicePurchaseOptionsDto>(
            getPurchaseOptionsEndpoint(medicalServiceId),
            {params: {occupationalMedicineReferralId}},
        );
    }

    getOccupationalMedicineMedicalServices() {
        return rawHttp.get<MedicalServiceDto[]>(
            OCCUPATIONAL_MEDICINE_MEDICAL_SERVICES,
        );
    }
}

export const medicalServiceApi = new MedicalServiceApi();
