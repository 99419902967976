import {
    Box,
    Button,
    Card,
    Checkbox,
    createStyles,
    Grid,
    Link,
    makeStyles,
    RadioGroup,
    Theme,
    Typography,
} from '@material-ui/core';
import {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ConfirmOrRejectModal, CustomModal, Loading, SelectButtons} from '../..';
import {AppointmentPurchaseType} from '../../../const/appointmentPurchaseType';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectAppointmentPurchaseOptions,
    selectSelectedAppointment,
    selectSlotsInTimeWindow,
} from '../../../store/timeSlotsSlice';
import {SelectButtonOption} from '../../common/SelectButtons/SelectButtons';
import PatientGroupPackagePurchaseOption from './PatientGroupPackagePurchaseOption/GroupPackagePurchaseOption';
import AppointmentPatientPurchaseOption from './AppointmentPatientPurchaseOption/AppointmentPatientPurchaseOption';
import {getFilteredAppointmentTypes} from '../../../utils/appointment';
import {InfoOutlined} from '@material-ui/icons';
import {TIME_SLOTS_PAGE, USER_ACCOUNT_PAGE} from '../../../const/routes';
import {useHistory} from 'react-router';
import {isNotNullOrUndefined} from '../../../utils/lang';
import {getLanguage, getTranslation} from '../../../utils/translation';
import i18n from '../../../i18n/config';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';
import useQuery from '../../../hooks/useQuery';
import configurationProvider from '../../../services/configurationProvider';
import {parseTimeSlotQuery} from '../../../utils/timeSlots';
import {DateTime} from 'luxon';
import {BACKGROUND_PRIMARY, GRAY} from '../../../const/colors';
import {selectAuthUserData} from '../../../store/auth';
import {PATIENT} from '../../../const/auth';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectButton: {
            [theme.breakpoints.up('lg')]: {
                width: 180,
                marginRight: 10,
                '&:last-child': {
                    marginRight: 0,
                },
            },
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        addToQueueContainer: {
            marginTop: 23,
            display: 'flex',
            alignItems: 'center',
        },
        addToQueueCheckbox: {
            padding: 0,
            marginRight: 10,
        },
    }),
);

type InfoReason = 'EMPTY_PACKAGE' | 'NOT_EMPTY_PACKAGE' | null;

type AppointmentPatientPurchaseOptionsProps = {
    onSubmit?: (
        appointmentType: string,
        appointmentPurchaseType: AppointmentPurchaseType,
        addPatientToQueue: boolean,
        individualPackageDraftId?: number,
        patientGroupDraftId?: number,
    ) => void;
    selectedChildId: number;
    disabled: boolean;
};

const AppointmentPatientPurchaseOptions = ({
    onSubmit,
    selectedChildId,
    disabled,
}: AppointmentPatientPurchaseOptionsProps) => {
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t} = useTranslation(i18nNamespaces.TIME_SLOTS);
    const history = useHistory();
    const currentLanguage = getLanguage(i18n?.language);

    const purchaseOptions = useAppSelector(selectAppointmentPurchaseOptions);
    const selectedAppointment = useAppSelector(selectSelectedAppointment);
    const slotsInTimeWindow = useAppSelector(selectSlotsInTimeWindow);
    const authUserData = useAppSelector(selectAuthUserData);

    const query = useQuery();
    const medicalServiceId = parseInt(query.get('medicalServiceId'));
    const startDateTime = DateTime.fromISO(query.get('startDateTime'));
    const endDateTime = DateTime.fromISO(query.get('endDateTime'));

    const [infoReason, setInfoReason] = useState<InfoReason>(null);
    const {briefMedic} = selectedAppointment;
    const currentMedicalServiceData = briefMedic.medicalServices.find(
        m => m.id === medicalServiceId,
    );
    const availableChannels = {
        videoChannel:
            briefMedic?.videoChannel && currentMedicalServiceData?.videoChannel,
        chatChannel:
            briefMedic?.chatChannel && currentMedicalServiceData?.chatChannel,
        callChannel:
            briefMedic?.callChannel && currentMedicalServiceData?.callChannel,
    };

    const [
        generalPractitionerAppointmentId,
        setGeneralPractitionerAppointmentId,
    ] = useState<number>(null);
    const [
        showPrescriptionAppointmentWarining,
        setShowPrescriptionAppointmentWarning,
    ] = useState<boolean>(false);
    const [
        shouldShowshowPrescriptionAppointmentWarining,
        setShouldShowshowPrescriptionAppointmentWarining,
    ] = useState<boolean>(false);

    const [selectedType, setSelectedType] = useState<SelectButtonOption>(
        getFilteredAppointmentTypes(
            commonT,
            availableChannels.videoChannel,
            availableChannels.chatChannel,
            availableChannels.callChannel,
        )[0],
    );
    const {getRoute} = useLandingPageUrl();

    const [selectedRadioPurchaseOption, setRadioPurchaseOption] =
        useState<string>('OneTime' as AppointmentPurchaseType);

    const getDefaultAppointmentsId = async () => {
        const prescriptionId =
            await configurationProvider.getPrescriptionAppointmentId();

        if (
            isNotNullOrUndefined(medicalServiceId) &&
            prescriptionId.data === medicalServiceId
        )
            setShouldShowshowPrescriptionAppointmentWarining(true);

        const generalPractitionerId =
            await configurationProvider.getGeneralPractitionerAppointmentId();
        setGeneralPractitionerAppointmentId(generalPractitionerId.data);
    };

    useEffect(() => {
        getDefaultAppointmentsId();
    }, []);

    useEffect(() => {
        let appointmentPurchaseType: AppointmentPurchaseType | null = null;
        if (selectedRadioPurchaseOption.includes('_')) {
            const purchaseOptionFragments =
                selectedRadioPurchaseOption.split('_');
            appointmentPurchaseType =
                purchaseOptionFragments[0] as AppointmentPurchaseType;
            const id = parseInt(purchaseOptionFragments[1]);
            if (appointmentPurchaseType === 'GroupPackage') {
                const found = purchaseOptions.groupPackageOptions.some(
                    x => x.patientGroupDraftId === id,
                );
                if (!found)
                    setRadioPurchaseOption(
                        'OneTime' as AppointmentPurchaseType,
                    );
            }
        }
    }, [selectedChildId, purchaseOptions]);

    const [addToQueueCheckboxChecked, setAddToQueueCheckboxChecked] =
        useState(false);
    const addToQueueCheckboxToggle = () => {
        setAddToQueueCheckboxChecked(checked => !checked);
    };

    const setOption = (e: ChangeEvent<HTMLInputElement>) => {
        setRadioPurchaseOption(e.target.value);
    };

    useEffect(() => {
        setSelectedType(
            getFilteredAppointmentTypes(
                commonT,
                availableChannels.videoChannel,
                availableChannels.chatChannel,
                availableChannels.callChannel,
            )[0],
        );
    }, [selectedAppointment]);

    const handleOnPrescriptionAppointmentwarningConfirm = () => {
        handleSubmit();
    };

    // On reject booking appointment with required prescription redirect to general practitioner appointment
    const handleOnPrescriptionAppointmentwarningReject = () => {
        setShowPrescriptionAppointmentWarning(false);
        if (isNotNullOrUndefined(generalPractitionerAppointmentId)) {
            const search = parseTimeSlotQuery({
                medicalServiceId: generalPractitionerAppointmentId,
                startDateTime,
                endDateTime,
            });
            const url = TIME_SLOTS_PAGE + search;

            history.push(url);
            window.location.reload();
        }
    };

    const handleSubmit = () => {
        let appointmentPurchaseType: AppointmentPurchaseType | null = null;
        let individualPackageDraftId: number = null;
        let patientGroupDraftId: number = null;

        if (selectedRadioPurchaseOption.includes('_')) {
            const purchaseOptionFragments =
                selectedRadioPurchaseOption.split('_');
            appointmentPurchaseType =
                purchaseOptionFragments[0] as AppointmentPurchaseType;
            const id = parseInt(purchaseOptionFragments[1]);
            if (appointmentPurchaseType === 'IndividualPackage') {
                individualPackageDraftId = id;
            }
            if (appointmentPurchaseType === 'GroupPackage') {
                patientGroupDraftId = id;
            }
        } else {
            appointmentPurchaseType =
                selectedRadioPurchaseOption as AppointmentPurchaseType;
        }

        onSubmit(
            selectedType.value as string,
            appointmentPurchaseType,
            addToQueueCheckboxChecked,
            individualPackageDraftId,
            patientGroupDraftId,
        );
    };

    const handlePackageInfoIconClick = (remainingLimit: number) => {
        setInfoReason(
            remainingLimit === 0 ? 'EMPTY_PACKAGE' : 'NOT_EMPTY_PACKAGE',
        );
    };

    const classes = useStyles();

    const handleClickOkInModal = () => {
        infoReason === 'EMPTY_PACKAGE'
            ? (window.location.href = getRoute('INDIVIDUAL_PACKAGES'))
            : history.push(USER_ACCOUNT_PAGE + '/your-bundles');
    };

    return purchaseOptions ? (
        <>
            <Box mt={1}>
                {/*{briefMedic && (*/}
                {/*    <SelectButtons*/}
                {/*        options={getFilteredAppointmentTypes(*/}
                {/*            commonT,*/}
                {/*            availableChannels.videoChannel,*/}
                {/*            availableChannels.chatChannel,*/}
                {/*            availableChannels.callChannel,*/}
                {/*        )}*/}
                {/*        onChange={setSelectedType}*/}
                {/*        buttonStyles={classes.selectButton}*/}
                {/*        containerStyles={classes.buttonsContainer}*/}
                {/*        value={selectedType}*/}
                {/*    />*/}
                {/*)}*/}
            </Box>
            <Box pb={2}>
                <RadioGroup
                    name="purchaseType"
                    value={selectedRadioPurchaseOption}
                    onChange={setOption}
                >
                    {purchaseOptions.groupPackageOptions &&
                        purchaseOptions.groupPackageOptions.map(
                            ({
                                b2BClientName,
                                name,
                                patientGroupDraftId,
                                price,
                                validUntil,
                                remainingLimit,
                                hideLimit,
                            }) => {
                                const id = `${
                                    'GroupPackage' as AppointmentPurchaseType
                                }_${patientGroupDraftId}`;

                                return (
                                    <PatientGroupPackagePurchaseOption
                                        key={id}
                                        b2bClientName={b2BClientName}
                                        groupPackageName={name}
                                        id={id}
                                        limit={remainingLimit}
                                        price={price}
                                        validUntil={validUntil}
                                        hideLimit={hideLimit}
                                        infoIcon={
                                            <Link
                                                onClick={() =>
                                                    handlePackageInfoIconClick(
                                                        remainingLimit,
                                                    )
                                                }
                                            >
                                                <InfoOutlined
                                                    fontSize={'small'}
                                                />
                                            </Link>
                                        }
                                    />
                                );
                            },
                        )}
                    {purchaseOptions.individualPackageOptions &&
                    purchaseOptions.individualPackageOptions.length > 0
                        ? purchaseOptions.individualPackageOptions.map(
                              individualPackageOption => {
                                  const id = `${
                                      'IndividualPackage' as AppointmentPurchaseType
                                  }_${
                                      individualPackageOption.individualPackageDraftId
                                  }`;
                                  return (
                                      <AppointmentPatientPurchaseOption
                                          key={id}
                                          id={id}
                                          label={getTranslation(
                                              individualPackageOption.name,
                                              individualPackageOption.translations,
                                              currentLanguage,
                                              'name',
                                          )}
                                          price={individualPackageOption.price}
                                          limit={
                                              individualPackageOption.remainingLimit
                                          }
                                          hideLimit={
                                              individualPackageOption.hideLimit
                                          }
                                          infoIcon={
                                              <Link
                                                  onClick={() =>
                                                      handlePackageInfoIconClick(
                                                          individualPackageOption.remainingLimit,
                                                      )
                                                  }
                                                  style={{
                                                      width: 15,
                                                      height: 15,
                                                  }}
                                              >
                                                  <InfoOutlined
                                                      fontSize={'small'}
                                                  />
                                              </Link>
                                          }
                                      />
                                  );
                              },
                          )
                        : purchaseOptions.individualPackageOffer && (
                              <AppointmentPatientPurchaseOption
                                  id={`${
                                      'IndividualPackageOffer' as AppointmentPurchaseType
                                  }_${
                                      purchaseOptions.individualPackageOffer
                                          .draftId
                                  }`}
                                  label={
                                      <>
                                          {t('buyIndividualPackageFrom')}{' '}
                                          <span style={{fontWeight: 'bold'}}>
                                              {
                                                  purchaseOptions
                                                      .individualPackageOffer
                                                      .packagePrice
                                              }{' '}
                                              zł / {t('perMonthShort')}
                                          </span>
                                      </>
                                  }
                                  price={
                                      purchaseOptions.individualPackageOffer
                                          .price
                                  }
                              />
                          )}
                    <AppointmentPatientPurchaseOption
                        id={'OneTime' as AppointmentPurchaseType}
                        label={t('oneTimeAppointment')}
                        price={purchaseOptions.oneTimePrice}
                    />
                </RadioGroup>
            </Box>
            {authUserData.role === PATIENT && (
                <>
                    <Box
                        style={{
                            height: 20,
                            backgroundColor: BACKGROUND_PRIMARY,
                            margin: '0 -25px',
                            marginBottom: 20,
                        }}
                    />
                    <Button
                        disabled={disabled}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => {
                            if (shouldShowshowPrescriptionAppointmentWarining)
                                setShowPrescriptionAppointmentWarning(true);
                            else handleSubmit();
                        }}
                    >
                        {t('bookConsultation')}
                    </Button>

                    {!slotsInTimeWindow && (
                        <Box className={classes.addToQueueContainer}>
                            <Checkbox
                                className={classes.addToQueueCheckbox}
                                checked={addToQueueCheckboxChecked}
                                onChange={addToQueueCheckboxToggle}
                            />
                            {t(
                                'notifyMeWhenAppointmentWithEarlierDateAvailable',
                            )}
                        </Box>
                    )}
                </>
            )}
            <CustomModal
                open={!!infoReason}
                onCloseButtonClick={() => setInfoReason(null)}
                onClose={() => setInfoReason(null)}
            >
                <>
                    <Typography
                        style={{
                            width: 500,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        {infoReason === 'EMPTY_PACKAGE'
                            ? t('emptyPackageModalInfo')
                            : t('notEmptyPackageModalInfo')}
                    </Typography>
                    <Grid container direction={'column'}>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{marginBottom: 10}}
                            onClick={handleClickOkInModal}
                        >
                            OK
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => setInfoReason(null)}
                        >
                            {t('noThanks')}
                        </Button>
                    </Grid>
                </>
            </CustomModal>
            <ConfirmOrRejectModal
                open={showPrescriptionAppointmentWarining}
                primaryText={t('prescriptionAppointmentWarningTitle')}
                secondaryText={t('prescriptionAppointmentWarning')}
                confirmButtonText={t('prescriptionAppointmentWarningConfirm')}
                rejectButtonText={t('prescriptionAppointmentWarningReject')}
                onConfirm={handleOnPrescriptionAppointmentwarningConfirm}
                onReject={handleOnPrescriptionAppointmentwarningReject}
            />
        </>
    ) : (
        <Loading loading withBackdrop />
    );
};

export default AppointmentPatientPurchaseOptions;
