import {
    Avatar,
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {ArrowForwardIos} from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import {LIGHT_GRAY, MAGENTA} from '../../../../const/colors';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {selectselectedTimeSlotId} from '../../../../store/timeSlotsSlice';
import {TimeSlot} from '../../../../types/timeSlot';
import {getRelativeDay} from '../../../../utils/date';
import {isNotNullOrUndefined} from '../../../../utils/lang';
import CustomRating from '../../../common/CustomRating/CustomRating';
import useStyles from './MedicItemStyles';
import {getLanguage} from '../../../../utils/translation';
import i18n from '../../../../i18n/config';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {t} from 'i18next';

interface Props {
    item?: TimeSlot;
    onClick?: () => void;
    showBookButton?: boolean;
}

const MedicItem = ({item, onClick, showBookButton}: Props) => {
    const selectedTimeSlotId = useAppSelector(selectselectedTimeSlotId);
    const classes = useStyles();
    const {briefMedic, startDate} = item;
    const isSelected = item?.id === selectedTimeSlotId;
    const {name, surname, title, image, specialties, reviewRate, reviewCount} =
        briefMedic;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const currentLanguage = getLanguage(i18n?.language);

    const getSpecialtiesTranslation = () => {
        if (
            item &&
            isNotNullOrUndefined(item.briefMedic.specialtiesTranslations) &&
            item.briefMedic.specialtiesTranslations.length !== 0
        ) {
            const translation = item.briefMedic.specialtiesTranslations.find(
                o => {
                    return o.languageCode === currentLanguage;
                },
            );

            if (isNotNullOrUndefined(translation))
                return translation.specialties.join(', ');
        }

        return item.briefMedic.specialties.join(', ');
    };

    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);

    return (
        <Box key={item?.id} className={classes.container} onClick={onClick}>
            <ListItem className={classes.listItem}>
                <Box className={classes.timeslotWrapper}>
                    <Box
                        className={clsx(classes.timeBox, {
                            [classes.magentaBackground]:
                                isSelected && !isMobile,
                        })}
                    >
                        <Box
                            className={clsx(classes.dayNameWrapper, {
                                [classes.whiteFont]: isSelected && !isMobile,
                            })}
                        >
                            {getRelativeDay(startDate, 'd.MM').toUpperCase()}
                        </Box>
                        <Box
                            className={clsx(classes.timeWrapper, {
                                [classes.whiteFont]: isSelected && !isMobile,
                            })}
                        >
                            {currentLanguage === 'EN'
                                ? startDate.toFormat('hh:mm a')
                                : startDate.toFormat('HH:mm')}
                        </Box>
                    </Box>
                    <Box className={classes.medicInfoWrapper}>
                        <ListItemAvatar>
                            <Avatar
                                alt={name}
                                src={image}
                                className={clsx(classes.avatar, {
                                    [classes.selectedAvatar]: isSelected,
                                })}
                            />
                        </ListItemAvatar>
                        <Box className={classes.name}>
                            <ListItemText
                                primary={
                                    <Box
                                        fontWeight={'bold'}
                                    >{`${title} ${name} ${surname}`}</Box>
                                }
                                secondary={getSpecialtiesTranslation()}
                            />
                            <Box className={classes.ratingWrapper}>
                                <CustomRating
                                    defaultValue={reviewRate}
                                    readOnly
                                    size={'small'}
                                />
                                <Box
                                    component={'span'}
                                    className={classes.ratingText}
                                >
                                    ({reviewCount} {tCommon('ratings')})
                                </Box>
                            </Box>
                        </Box>
                        {!isMobile && (
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <ArrowForwardIos
                                        color={
                                            isSelected
                                                ? 'secondary'
                                                : 'disabled'
                                        }
                                    />
                                </IconButton>
                            </ListItemSecondaryAction>
                        )}
                    </Box>
                    {isMobile && showBookButton && (
                        <Box className={classes.bookButtonWrapper}>
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                disableElevation
                                style={{fontWeight: 'bold', marginTop: 20}}
                            >
                                {t('book')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </ListItem>
            {/* <Divider /> */}
        </Box>
    );
};

export default MedicItem;
