import React from 'react';
import {Box, Card} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {formatPrice} from '../../../utils/price';
import {desktop} from '../../../const/sizes';

type PriceCardProps = {
    price: number;
};
const PriceCard = ({price}: PriceCardProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    if (!price) return null;
    return (
        <Card
            elevation={0}
            style={{
                padding: desktop.contentSmallerSpacing,
                marginBottom: desktop.contentSmallerSpacing,
            }}
        >
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>{t('packagePriceMonthly')}</Box>
                <Box
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    {formatPrice(price)} zł
                </Box>
            </Box>
        </Card>
    );
};

export default PriceCard;
