import {Box, Button, Card, Link, makeStyles, Theme} from '@material-ui/core';
import FormikTextField from '../../common/form/FormikTextField/FormikTextField';
import FormikPhoneNumberField from '../../common/form/FormikPhoneNumberField/FormikPhoneNumberField';
import {useTranslation} from 'react-i18next';
import React, {useContext, useEffect, useRef, useState} from 'react';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {accountApi} from '../../../api/account/accountApi';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectAuthError,
    selectAuthUserData,
    verifySMSCode,
} from '../../../store/auth';
import {Loading} from '../../index';
import axios from 'axios';
import {AlertContext} from '../../../contexts/AlertContext';
import SendCodeAgain from '../../common/SendCodeAgain/SendCodeAgain';
import FormMessage from '../../common/form/FormMessage/FormMessage';
import {desktop, mobile} from '../../../const/sizes';
import {VERIFICATION_CODE_MAX_LENGTH} from '../../../const/auth';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: mobile.contentLargerSpacing,
        marginBottom: desktop.contentSmallerSpacing,
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            padding: desktop.contentSmallerSpacing,
        },
    },
}));

const REQUIRED_STRING = 'requiredField';

type PhoneConfirmationCardProps = {
    onSubmit?: () => void;
    submitLabel?: string;
};
const PhoneConfirmationCard = ({
    onSubmit,
    submitLabel,
}: PhoneConfirmationCardProps) => {
    const classes = useStyles();
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const authUserData = useAppSelector(selectAuthUserData);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const openAlert = useContext(AlertContext);
    const errorMessage = useAppSelector(selectAuthError);
    const formRef = useRef();

    const formik = useFormik({
        initialValues: {
            email: authUserData?.email,
            phoneNumber: '',
            code: '',
        },
        validationSchema: yup.object({
            code: yup
                .string()
                .min(6, commonT('minCharacters', {minChars: 6}))
                .required(authT(REQUIRED_STRING)),
        }),
        onSubmit: async values => {
            try {
                setLoading(true);
                const {email, code} = values;
                await dispatch(verifySMSCode({email, code}));
                onSubmit && onSubmit();
            } catch (error) {
                console.error(error);
                if (axios.isAxiosError(error))
                    openAlert({
                        severity: 'error',
                        message: t(error.response?.data[0]),
                    });
            } finally {
                setLoading(false);
            }
        },
    });

    const fetchPhoneNumber = async () => {
        try {
            const {data} = await accountApi.getUserPhoneNumber();
            await formik.setFieldValue('phoneNumber', data?.phoneNumber);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchPhoneNumber();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} ref={formRef}>
            <Loading loading={loading} withBackdrop />
            <Card elevation={0} className={classes.container}>
                <Box
                    style={{
                        fontWeight: 'bold',
                        marginBottom: 8,
                    }}
                >
                    {t('toActivateAccountConfirmPhoneNumber')}
                </Box>
                <Box>{t('rewriteActivationCodeReceivedInSMS')}</Box>
                <FormikTextField
                    form={formik}
                    label={authT('emailAddress') + ' *'}
                    fieldName={'email'}
                    disabled
                    fullWidth
                />
                <FormikPhoneNumberField
                    form={formik}
                    label={authT('phoneNumber') + ' *'}
                    fieldName={'phoneNumber'}
                    disabled
                    fullWidth
                />
                <FormikTextField
                    form={formik}
                    label={authT('verificationCode') + ' *'}
                    fieldName={'code'}
                    autoComplete="one-time-code"
                    inputProps={{maxLength: VERIFICATION_CODE_MAX_LENGTH}}
                    fullWidth
                />
                <SendCodeAgain mt={desktop.contentSmallerSpacing} />
                <FormMessage
                    message={authT(errorMessage)}
                    mt={desktop.contentSmallerSpacing}
                />
                <Button
                    style={{marginTop: desktop.contentSmallerSpacing}}
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    fullWidth
                >
                    {submitLabel || t('createAnAccountAndBuyPackage')}
                </Button>
            </Card>
        </form>
    );
};

export default PhoneConfirmationCard;
